import { api } from '@meterup/proto';
import { match } from 'ts-pattern';
import * as z from 'zod';

export const formatLifecycleStatus = (lifecycleStatus: api.LifecycleStatus) =>
  match(lifecycleStatus)
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY, () => 'Installed - primary')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP, () => 'Installed - backup')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_NOT_INSTALLED, () => 'Not installed')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_UNKNOWN, () => 'Unknown')
    .exhaustive();

export const validLifecycleStatus = z.nativeEnum(api.LifecycleStatus);
