import type { SelectProps } from '@react-types/select';
import type { AriaListBoxOptions } from 'react-aria';
import React from 'react';
import { HiddenSelect, OverlayContainer, useButton, useSelect } from 'react-aria';
import { mergeRefs } from 'react-merge-refs';
import { useSelectState } from 'react-stately';
import useMeasure from 'react-use-measure';
import { api } from '@meterup/proto';

import Icon from '../../assets/Icon/Icon';
import { FocusRingSelf } from '../../common/focus_rings';
import ListBox from '../../components/ListBox/ListBox';
import { colors, darkThemeSelector, fonts, styled } from '../../stitches.config';
import { SelectArrows } from './SelectArrows';
import SelectPopover from './SelectPopover';

const Container = styled('div', {
  position: 'relative',
  width: '100%',
});

const Button = styled('button', FocusRingSelf, {
  alignItems: 'center',
  display: 'flex',
  borderRadius: '$6',
  flexDirection: 'row',
  gap: '$8',
  width: '100%',
  font: fonts.sans,
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-50'],
  },
  variants: {
    size: {
      medium: {
        gap: '$6',
        lineHeight: '$20',
        fontSize: '$14',
        paddingX: '$8',
        paddingY: '$4',
      },
      large: {
        gap: '$8',
        lineHeight: '$24',
        fontSize: '$16',
        paddingX: '$12',
        paddingY: '$8',
      },
    },
  },
});

const RenderedText = styled('span', { truncate: true });

export interface SidebarLocationControlOption {
  value: string;
  label: string;
  lifecycleStatus: api.LifecycleStatus;
}

export type SidebarLocationControlSize = 'medium' | 'large';

export interface SidebarLocationControlInternalProps
  extends Omit<SelectProps<SidebarLocationControlOption>, 'label'> {
  size?: SidebarLocationControlSize;
  'aria-label': string;
}

export const SidebarLocationControlInternal: React.FC<SidebarLocationControlInternalProps> = ({
  size = 'medium',
  ...props
}) => {
  const state = useSelectState<SidebarLocationControlOption>(props);
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [triggerMeasureRef, { width: triggerWidth }] = useMeasure();

  const { triggerProps, valueProps, menuProps } = useSelect<SidebarLocationControlOption>(
    { ...props, label: null },
    state,
    triggerRef,
  );

  const { buttonProps } = useButton(triggerProps, triggerRef);

  return (
    <Container>
      <HiddenSelect state={state} triggerRef={triggerRef} label={props['aria-label']} />
      <Button
        {...buttonProps}
        ref={mergeRefs([triggerMeasureRef, triggerRef])}
        type="button"
        size={size}
      >
        <Icon icon="location" color={{ light: 'gray-500', dark: 'gray-300' }} />
        <RenderedText {...valueProps}>{state.selectedItem?.rendered}</RenderedText>
        <SelectArrows size={size} aria-hidden="true" />
      </Button>
      {state.isOpen && (
        <OverlayContainer>
          <SelectPopover
            triggerRef={triggerRef}
            popoverRef={popoverRef}
            size={size}
            width={triggerWidth}
            isOpen={state.isOpen}
            onClose={state.close}
          >
            <ListBox
              {...(menuProps as AriaListBoxOptions<SidebarLocationControlOption> & {
                children: any[];
              })}
              listBoxState={state}
              listBoxRef={popoverRef}
              size={size}
            />
          </SelectPopover>
        </OverlayContainer>
      )}
    </Container>
  );
};
