import { Header, HeaderLogo } from '@meterup/metric';
import React from 'react';
import { useFocusVisible } from 'react-aria';

import { focusVisibleClassName, styled } from '../stitches';

const Container = styled('div', {
  height: '100%',
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content minmax(0, 1fr)',
  gridTemplateAreas: '"header" "content"',
});

const HeaderContainer = styled('div', {
  gridArea: 'header',
  display: 'block',
  zIndex: 3,
  '@maxSm': {
    display: 'none',
  },
});

const ContentContainer = styled('div', {
  gridArea: 'content',
  overflow: 'auto',
  zIndex: 1,
});

export const AppLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const { isFocusVisible } = useFocusVisible();

  return (
    <Container className={isFocusVisible ? focusVisibleClassName : ''}>
      <HeaderContainer>
        <Header logo={<HeaderLogo href="/" />} />
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};
